<script>
import usersService from '@/services/users.service'

export default {
  name: 'Users',
  data() {
    return {
      items: [],
      headerFields: [
        "__slot:checkboxes",
        {
          name: "published",
          label: this.$t('active'),
          customElement: "published",
        },
        {
          name: "photo",
          label: this.$tc('photo'),
          customElement: "photo"
        },
        {
          name: "name",
          label: this.$t('firstName'),
        },
        {
          name: "surname",
          label: this.$t('lastName'),
        },
        {
          name: "username",
          label: this.$t('username'),
        },
        {
          name: "role_id",
          label: this.$tc('role'),
          format: role => this.$store.state.roles.roles && this.$store.getters['roles/filterRoleById'](role).name,
        },
        {
          name: "last_login",
          label: this.$t('lastLogin'),
          format: date => new Date(date).toLocaleString('en-GB'),
        },
        "__slot:actions:edit",
        "__slot:actions:delete",
      ],
    }
  },
	mounted() {
    this._get()
    this.$store.dispatch('roles/getAllRoles')
	},
	methods: {
    _get() {
      usersService.getAll()
      .then(res => {
        this.items = res.data
      })
    },
    remove(id) {
      usersService.remove(id)
        .then(success => {
          this.$notify({
              message: this.$t('m.itemSuccessfullyDeleted', { item: this.$tc('user') }),
              icon: "far fa-bell",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
        })
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="form-group col-12 text-right">
              <router-link :to="{ name: 'singleUser', params: { id: 'new' } }">
                <BaseButton 
                  type="green"
                  gradient
                >
                  {{ $t('addNewItem', { item: $tc('user')}) }}
                </BaseButton>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <BaseListTable
            :items="items"
            :header-fields="headerFields"
            type="users"
            @delete="remove"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
